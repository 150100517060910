<template>
  <div>
    <Header>
      <template #right-header-component>
        <profile-drop-down/>
      </template>
    </Header>
    <div class="job__screening__criteria px-5 mx-auto">
      <div class="my-3 d-none d-sm-block">
        <v-btn text class="pl-0" @click="$router.back()">
          <v-icon>mdi-chevron-left</v-icon>
          Back
        </v-btn>
      </div>
      <div>
        <h3>Job Screening Criteria</h3>
        <small>For {{jobRole}}, Created on <br/> {{new Date().toDateString()}}</small>
      </div>
      <div>
        <v-tabs slider-size="3">
          <v-tab
              class="px-0 mr-4 text-capitalize"
              v-for="(tab, i) in tabs"
              :key="i"
              :to="tab.to"
          >
            {{ tab.name }}
          </v-tab>
        </v-tabs>
      </div>
      <router-view/>
    </div>
  </div>
</template>

<script>
import Header from "@/components/onboarding/reuseables/Header";
import ProfileDropDown from "@/components/dashboard/reuseables/ProfileDropDown";

export default {
  name: "JobScreeningCriteriaView",
  components: {
    ProfileDropDown,
    Header
  },
  data: () => ({
    jobRole: "Senior Engineer",
    tabs: [
      {
        name: 'Test Library',
        to: {name: 'TestLibrary'}
      },
      {
        name: 'Saved Tests',
        to: {name: 'SavedTests'}
      }
    ],
  }),
}
</script>

<style scoped lang="scss">
.job__screening__criteria {
  width: 100%;
  max-width: 1400px;
  margin-top: 100px;

  h3 {
    color: #4F4F4F;
    font-weight: 600;
    font-size: 26px;
  }

  small {
    color: #475661;
  }
}

.sample_questions_title {
  color: #475661;
  font-family: "DM Sans", serif;
}
</style>